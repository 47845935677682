import { environments } from "configs";
import { Checkout } from "types";
import { HttpClient } from "./http-client";

// Products services class extends HttpClient
export class CheckoutServices extends HttpClient {
  private static classInstance?: CheckoutServices;

  private constructor() {
    // Call super class constructor with base url
    super(environments.CHECKOUT_URL);
  }

  // Get singleton instance
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new CheckoutServices();
    }

    return this.classInstance;
  }

  // Checkout
  public checkout = (amount: number) =>
    this.instance.get<Checkout>(`/`, {
      params: {
        amount,
      },
    });
}
