import { Box, Button, CircularProgress } from "@mui/material";

type Props = { loading?: boolean } & React.ComponentProps<typeof Button>;
const LoadingButton: React.FC<Props> = ({ loading, children, ...rest }) => {
  return (
    <Button
      disabled={loading}
      sx={{
        position: "relative",
      }}
      {...rest}
    >
      <CircularProgress
        size={24}
        sx={{
          display: loading ? "block" : "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-10px",
          marginLeft: "-10px",
        }}
      />
      <Box
        sx={{
          visibility: loading ? "hidden" : "visible",
        }}
      >
        {children}
      </Box>
    </Button>
  );
};

export default LoadingButton;
