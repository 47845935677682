import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Page } from "components";
import { useNavigate } from "react-router-dom";
const CardStyle = styled(Card)(({ theme }) => ({
  boxShadow: theme.customShadows.primary,
}));
const CheckoutConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const handleContinueShopping = () => {
    navigate("/");
  };
  return (
    <Page title="Checkout Confirmation">
      <Container>
        <CardStyle elevation={24}>
          <Box p={4}>
            <Typography variant="h5">Checkout Confirmation</Typography>
          </Box>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Box p={4}>
            <Typography variant="h6">Thank you for your order!</Typography>
            <Typography variant="body1">
              We are currently processing your order and will send you a
              confirmation email shortly.
            </Typography>

            <Typography variant="body1">
              Once your order has shipped, we will send you an email with
              additional information.
            </Typography>

            <Typography variant="body1">
              If you have any questions, please feel free to{" "}
              <Typography
                variant="body1"
                component="a"
                href="#"
                sx={{ color: "primary.main" }}
              >
                contact us
              </Typography>
              .
            </Typography>
          </Box>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Box p={4}>
            <Stack direction="row-reverse">
              <Button
                onClick={handleContinueShopping}
                data-testid="shopping-cart-continue-shopping"
              >
                Continue Shopping
              </Button>
            </Stack>
          </Box>
        </CardStyle>
      </Container>
    </Page>
  );
};

export default CheckoutConfirmation;
